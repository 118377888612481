import { WlcmOption } from '@wlcm/angular/core';

export const HeightOptions: WlcmOption[] = [
  { value: 'cm', viewValue: 'cm' },
  // { value: 'ft', viewValue: 'ft' },
  { value: 'in', viewValue: 'in' },
];

export const WeightOptions: WlcmOption[] = [
  { value: 'kg', viewValue: 'kg' },
  { value: 'lb', viewValue: 'lb' },
];
