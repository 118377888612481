import { WlcmOption } from '@wlcm/angular/core';

export enum TShirtSize {
  MONTHS_6 = 'm_6',
  MONTHS_12 = 'm_12',
  MONTHS_18 = 'm_18',
  TODDLER_2T = 't_2',
  TODDLER_3T = 't_3',
  TODDLER_4T = 't_4',
  KIDS_5_6 = 'k_5/6',
  KIDS_7 = 'k_7',
  YOUTH_S = 'y_S',
  YOUTH_M = 'y_M',
  YOUTH_L = 'y_L',
  YOUTH_XL = 'y_XL',
  ADULT_S = 'S',
  ADULT_M = 'M',
}

export const TShirtSizeMap: Record<TShirtSize, string> = {
  [TShirtSize.MONTHS_6]: '6 months',
  [TShirtSize.MONTHS_12]: '12 months',
  [TShirtSize.MONTHS_18]: '18 months',
  [TShirtSize.TODDLER_2T]: '2T',
  [TShirtSize.TODDLER_3T]: '3T',
  [TShirtSize.TODDLER_4T]: '4T',
  [TShirtSize.KIDS_5_6]: '5/6',
  [TShirtSize.KIDS_7]: '7',
  [TShirtSize.YOUTH_S]: 'Youth S',
  [TShirtSize.YOUTH_M]: 'Youth M',
  [TShirtSize.YOUTH_L]: 'Youth L',
  [TShirtSize.YOUTH_XL]: 'Youth XL',
  [TShirtSize.ADULT_S]: 'S',
  [TShirtSize.ADULT_M]: 'M',
};

export const TShirtSizeOptions: WlcmOption[] = [
  { value: '', viewValue: 'N/A' },
  { value: TShirtSize.MONTHS_6, viewValue: TShirtSizeMap[TShirtSize.MONTHS_6] },
  { value: TShirtSize.MONTHS_12, viewValue: TShirtSizeMap[TShirtSize.MONTHS_12] },
  { value: TShirtSize.MONTHS_18, viewValue: TShirtSizeMap[TShirtSize.MONTHS_18] },
  { value: TShirtSize.TODDLER_2T, viewValue: TShirtSizeMap[TShirtSize.TODDLER_2T] },
  { value: TShirtSize.TODDLER_3T, viewValue: TShirtSizeMap[TShirtSize.TODDLER_3T] },
  { value: TShirtSize.TODDLER_4T, viewValue: TShirtSizeMap[TShirtSize.TODDLER_4T] },
  { value: TShirtSize.KIDS_5_6, viewValue: TShirtSizeMap[TShirtSize.KIDS_5_6] },
  { value: TShirtSize.KIDS_7, viewValue: TShirtSizeMap[TShirtSize.KIDS_7] },
  { value: TShirtSize.YOUTH_S, viewValue: TShirtSizeMap[TShirtSize.YOUTH_S] },
  { value: TShirtSize.YOUTH_M, viewValue: TShirtSizeMap[TShirtSize.YOUTH_M] },
  { value: TShirtSize.YOUTH_L, viewValue: TShirtSizeMap[TShirtSize.YOUTH_L] },
  { value: TShirtSize.YOUTH_XL, viewValue: TShirtSizeMap[TShirtSize.YOUTH_XL] },
  { value: TShirtSize.ADULT_S, viewValue: TShirtSizeMap[TShirtSize.ADULT_S] },
  { value: TShirtSize.ADULT_M, viewValue: TShirtSizeMap[TShirtSize.ADULT_M] },
];
